<template>
  <q-page padding>
    <div class="row items-center justify-center" style="min-height: 60vh;">
      <q-card style="min-width: 30vw;">
        <q-card-title class="text-center" >
          <img src="statics/img/logo_icor_t.gif" v-if="getAppMode_ICOR">
        </q-card-title>
        <q-card-separator />
        <q-card-main>
          <q-field>
            <q-input autofocus float-label="nazwa użytkownika" v-model="username" @keyup.enter.native="doLogin" />
          </q-field>
          <q-field>
            <q-input float-label="hasło" type="password" v-model="password" @keyup.enter.native="doLogin" />
          </q-field>
          <br>
          <q-btn color="primary" icon="person" @click="doLogin()">loguj</q-btn>
        </q-card-main>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Login',
  computed: {
    ...mapGetters(['getAppMode_ICOR', 'getStartPage'])
  },
  data () {
    return {
      logo: 'Digitalizer',
      email: '',
      username: '',
      password: '',
      bgColor: 'blue',
      toneColor: 2,
      colors: ['purple', 'blue', 'red', 'green', 'amber'],
      colorTones: ['2', '4', '6', '8', '10'],
      buttonClasses: ['normal', 'outline', 'clear', 'push', 'bordered', 'round'],
      buttonClass: 'normal',
      vivus: ''
    }
  },
  created () {
    this.$icor.main.appSessionCheck()
  },
  methods: {
    ...mapMutations(['setUser_UserName', 'setUser_FirstName', 'setUser_LastName', 'setUser_Description', 'setUser_UID', 'setUser_EMail', 'setUser_Avatar']),

    loginRedirect () {
      let url = this.$route.query.redirect || this.getStartPage
      console.log('redirect:' + url)
      this.$router.replace(url)
      this.$nextTick(function () {
        this.$root.$emit('login')
      })
    },
    async doLogin () {
      let [status, info] = await this.$icor.main.login(this.username, this.password)
      if (status === 0) {
        return this.loginRedirect()
      } else {
        this.$q.notify({message: info, type: 'negative', position: 'bottom-right'})
      }
    }
  }
}
</script>

<style scoped>
.loginlogo {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size:32pt;
}
</style>
