var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { attrs: { padding: "" } }, [
    _c(
      "div",
      {
        staticClass: "row items-center justify-center",
        staticStyle: { "min-height": "60vh" }
      },
      [
        _c(
          "q-card",
          { staticStyle: { "min-width": "30vw" } },
          [
            _c("q-card-title", { staticClass: "text-center" }, [
              _vm.getAppMode_ICOR
                ? _c("img", { attrs: { src: "statics/img/logo_icor_t.gif" } })
                : _vm._e()
            ]),
            _c("q-card-separator"),
            _c(
              "q-card-main",
              [
                _c(
                  "q-field",
                  [
                    _c("q-input", {
                      attrs: {
                        autofocus: "",
                        "float-label": "nazwa użytkownika"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.doLogin($event)
                        }
                      },
                      model: {
                        value: _vm.username,
                        callback: function($$v) {
                          _vm.username = $$v
                        },
                        expression: "username"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "q-field",
                  [
                    _c("q-input", {
                      attrs: { "float-label": "hasło", type: "password" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.doLogin($event)
                        }
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "q-btn",
                  {
                    attrs: { color: "primary", icon: "person" },
                    on: {
                      click: function($event) {
                        return _vm.doLogin()
                      }
                    }
                  },
                  [_vm._v("loguj")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }